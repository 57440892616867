var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import cls from './SideBar.module.css';
import SideBarLogo from 'shared/assets/icons/SideLogo.svg';
import { RoutePaths } from 'app/providers/AppRouter';
import { Link } from 'react-router-dom';
import DashboardIcon from 'shared/assets/icons/Dashboard.svg';
import Profile from 'shared/assets/icons/profile.svg';
import LicensesIcon from 'shared/assets/icons/licenses.svg';
import Logout from 'shared/assets/icons/logout.svg';
import UsersIcon from 'shared/assets/icons/users-icon.svg';
import AccountsIcon from 'shared/assets/icons/accounts-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { UserRoles } from 'app/interfaces/User';
var ALLOWED_ROLES = [UserRoles.ADMIN, UserRoles.SUPER_ADMIN];
export var SideBar = memo(function () {
    var navigate = useNavigate();
    var logout = useAuthContext().logout;
    var user = useUserContext().user;
    var handleLogout = function () {
        logout();
        navigate(RoutePaths.LOGIN);
    };
    var isAdmin = useMemo(function () { return user && ALLOWED_ROLES.includes(user.role); }, [user]);
    if (!user)
        return null;
    return (_jsxs("div", __assign({ className: cls.SideBar }, { children: [_jsx("div", __assign({ className: cls.logocontainer }, { children: _jsx(Link, __assign({ className: cls.logo, to: RoutePaths.DASHBOARD }, { children: _jsx(SideBarLogo, {}) })) })), _jsxs("div", __assign({ className: cls.navigationcontainer }, { children: [_jsxs(Link, __assign({ className: cls.navitem, to: RoutePaths.DASHBOARD }, { children: [_jsx(DashboardIcon, { className: cls.navicon }), " Dashboard"] })), isAdmin ? (_jsxs(_Fragment, { children: [_jsxs(Link, __assign({ className: cls.navitem, to: RoutePaths.USERS }, { children: [_jsx(UsersIcon, { className: cls.navicon }), " Users"] })), _jsxs(Link, __assign({ className: cls.navitem, to: RoutePaths.LICENSES }, { children: [_jsx(LicensesIcon, { className: cls.navicon }), " Licenses"] })), _jsxs(Link, __assign({ className: cls.navitem, to: RoutePaths.ACCOUNTS }, { children: [_jsx(AccountsIcon, { className: cls.navicon }), " Accounts"] }))] })) : null, _jsxs(Link, __assign({ className: cls.navitem, to: RoutePaths.PROFILE }, { children: [_jsx(Profile, { className: cls.navicon }), " Profile"] }))] })), _jsxs("div", __assign({ className: cls.navifooter }, { children: [_jsxs("button", __assign({ id: "logout", className: cls.navitem, onClick: handleLogout }, { children: [_jsx(Logout, { className: cls.navicon }), " Log Out"] })), _jsx("div", { className: cls.divider }), _jsx("p", __assign({ className: cls.partners }, { children: _jsx("a", __assign({ className: cls.contact, href: "https://tomato.ai/request-support", target: "_blank", rel: "noreferrer" }, { children: "Report Issue" })) })), _jsx("p", __assign({ className: cls.copyrights }, { children: "\u00A9 Copyrights 2024. Tomato.ai" }))] }))] })));
});
SideBar.displayName = 'SideBar';
