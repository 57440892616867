var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './InfoBadge.module.css';
import InfoIcon from 'shared/assets/icons/info.svg';
import { Tooltip, TooltipVariants } from 'shared/components';
import { useState } from 'react';
export var InfoBadge = function (_a) {
    var text = _a.text;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (_jsxs("div", __assign({ className: cls.container }, { children: [_jsx("button", __assign({ className: cls.button, onMouseOver: function () { return setOpen(true); }, onMouseLeave: function () { return setOpen(false); } }, { children: _jsx(InfoIcon, {}) })), open &&
                _jsx(Tooltip, { text: text, variant: TooltipVariants.INFO })] })));
};
