import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from 'app/App';
import { Helmet } from 'react-helmet';
import favIcon from 'shared/assets/icons/favicon.png';
Sentry.init({
    dsn: 'https://88375b72a2d3c42f908f7e3cb2e79294@o4507623505264640.ingest.us.sentry.io/4507630693646336',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/app\.tomato\.ai/],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
var container = document.querySelector('#app');
var root = createRoot(container);
root.render(_jsxs(StrictMode, { children: [_jsx(Helmet, { children: _jsx("link", { rel: "shortcut icon", href: favIcon }) }), _jsx(App, {})] }));
