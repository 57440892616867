export var InputValidations;
(function (InputValidations) {
    InputValidations["MONEY"] = "money";
    InputValidations["NUMBER"] = "number";
})(InputValidations || (InputValidations = {}));
export var validation = function (value, validationType) {
    if (validationType === undefined)
        return value;
    if (value === '')
        return value;
    if (validationType === InputValidations.MONEY) {
        var newValue = value.split('').filter(function (char) { return char !== '$'; }).join('');
        if (isNaN(+newValue))
            return null;
        if (newValue.includes('.') || newValue.includes(','))
            return null;
        return newValue.trim();
    }
    if (validationType === InputValidations.NUMBER) {
        if (isNaN(+value) || value.includes('.') || value.includes(','))
            return null;
        return value.trim();
    }
    return null;
};
