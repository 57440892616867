var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './Input.module.css';
import { InfoBadge } from 'shared/components';
import { memo, useEffect, useState } from 'react';
import { InputValidations, validation } from 'shared/lib';
import classNames from 'classnames';
export var Input = memo(function (props) {
    var _a, _b;
    var label = props.label, type = props.type, info = props.info, placeholder = props.placeholder, validationType = props.validationType, value = props.value, onInputChange = props.onInputChange, required = props.required, classNamesProps = props.classNamesProps, rows = props.rows, doubleLabel = props.doubleLabel, max = props.max, error = props.error, isValid = props.isValid, reduced = props.reduced, disabled = props.disabled;
    var _c = useState(value), inputValue = _c[0], setInputValue = _c[1];
    var changeHandler = function (e) {
        var value = e.target.value;
        var newValue = validation(value, validationType);
        if (newValue !== null) {
            onInputChange(newValue);
        }
    };
    useEffect(function () {
        if (value !== '')
            setInputValue(validationType === InputValidations.MONEY ? "$".concat(value) : value);
        else
            setInputValue(value);
    }, [value]);
    return (_jsxs("div", __assign({ className: classNames(cls.container, classNamesProps, (_a = {},
            _a[cls.error] = isValid === false,
            _a[cls.reduced] = reduced,
            _a)) }, { children: [_jsxs("div", __assign({ className: classNames(cls.label, (_b = {},
                    _b[cls.doubleLine] = doubleLabel,
                    _b)) }, { children: [_jsx("span", __assign({ className: cls.labelText }, { children: label })), info && _jsx(InfoBadge, { text: info }), isValid === false && (_jsx("span", __assign({ className: cls.errorMessage }, { children: error })))] })), rows ? (_jsx("textarea", { value: inputValue, onChange: changeHandler, className: classNames(cls.input, cls.area), placeholder: placeholder, rows: rows, maxLength: max })) : (_jsx("input", { required: required, value: inputValue, onChange: changeHandler, className: cls.input, type: type, placeholder: placeholder, maxLength: max, disabled: disabled }))] })));
});
Input.displayName = 'Input';
