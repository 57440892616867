var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import cls from './TextInput.module.css';
import ViewPassword from 'shared/assets/icons/password-view.svg';
import ViewPasswordOff from 'shared/assets/icons/password-view-off.svg';
import clx from 'classnames';
var TextInput = function (_a) {
    var label = _a.label, _b = _a.placeholder, placeholder = _b === void 0 ? label : _b, showViewPasswordIcon = _a.showViewPasswordIcon, type = _a.type, error = _a.error, className = _a.className, containerClassName = _a.containerClassName, inputProps = __rest(_a, ["label", "placeholder", "showViewPasswordIcon", "type", "error", "className", "containerClassName"]);
    var _c = useState(false), viewPassword = _c[0], setViewPassword = _c[1];
    var onPasswordViewClick = function () {
        setViewPassword(!viewPassword);
    };
    var inputType = useMemo(function () { return (viewPassword ? 'text' : type); }, [viewPassword, type]);
    return (_jsxs("div", __assign({ className: clx([cls.container, containerClassName]) }, { children: [label ? _jsx("label", { children: label }) : null, _jsx("input", __assign({}, inputProps, { className: clx([cls.input, className, error && cls.error]), placeholder: placeholder, type: inputType })), showViewPasswordIcon ? (viewPassword ? (_jsx(ViewPassword, { className: cls.viewPassword, onClick: onPasswordViewClick, viewBox: "0 0 24 24", width: "30", height: "30" })) : (_jsx(ViewPasswordOff, { className: cls.viewPassword, onClick: onPasswordViewClick, viewBox: "0 0 24 24", width: "30", height: "30" }))) : null, error ? (_jsx("div", __assign({ id: "error-message", className: cls.errorMessage }, { children: error }))) : null] })));
};
export default TextInput;
