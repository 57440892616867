import { createContext, useContext } from 'react';
export var BannerTypes;
(function (BannerTypes) {
    BannerTypes["ERROR"] = "error";
})(BannerTypes || (BannerTypes = {}));
export var BannerContext = createContext(undefined);
export var useBannerContext = function () {
    var context = useContext(BannerContext);
    if (!context) {
        throw new Error('useBanner must be used within an BannerProvider');
    }
    return context;
};
