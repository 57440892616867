var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { OrganizationContext } from './organization-context';
import { gql, useQuery } from '@apollo/client';
import { useBannerContext } from '../BannerProvider/banner-context';
var ORGANIZATION_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query Organization($organizationId: ID!) {\n        organization(organizationId: $organizationId) {\n            id\n            name\n            users {\n                id\n                firstName\n                lastName\n            }\n        }\n    }\n"], ["\n    query Organization($organizationId: ID!) {\n        organization(organizationId: $organizationId) {\n            id\n            name\n            users {\n                id\n                firstName\n                lastName\n            }\n        }\n    }\n"])));
export var OrganizationProvider = function (_a) {
    var children = _a.children, organizationId = _a.organizationId;
    var _b = useState(), organization = _b[0], setOrganization = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(), error = _d[0], setError = _d[1];
    var show = useBannerContext().show;
    var organizationQuery = useQuery(ORGANIZATION_QUERY, {
        variables: {
            organizationId: organizationId,
        },
        skip: !organizationId,
    });
    useEffect(function () {
        var _a;
        if (!organizationQuery.loading) {
            setLoading(false);
            setOrganization((_a = organizationQuery.data) === null || _a === void 0 ? void 0 : _a.organization);
            setError(organizationQuery.error);
        }
    }, [organizationQuery]);
    useEffect(function () {
        if (error) {
            show(error.message);
        }
    }, [error]);
    return (_jsx(OrganizationContext.Provider, __assign({ value: {
            organization: organization,
            error: error,
            loading: loading,
        } }, { children: children })));
};
var templateObject_1;
