var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import { Footer, Header } from 'widgets';
import { SideBar } from 'widgets/SideBar/SideBar';
import cls from './Layout.module.css';
import DowntimeScreen from 'sections/DowntimeScreen/DowntimeScreen';
var Layout = function (_a) {
    var children = _a.children;
    var isAuthorized = useAuthContext().isAuthorized;
    // TODO: Dynamically change these variables
    var isDowntime = false;
    var backOnlineDate = new Date();
    if (isDowntime) {
        return (_jsx("div", __assign({ className: "app light" }, { children: _jsx(DowntimeScreen, { backOnlineDate: backOnlineDate }) })));
    }
    return (_jsx("div", __assign({ className: "app light", id: "app-layout" }, { children: isAuthorized ? (_jsxs(_Fragment, { children: [_jsx(SideBar, {}), _jsx(Header, {}), _jsx("div", __assign({ className: cls.container }, { children: _jsx("div", __assign({ className: cls.content }, { children: children })) })), _jsx(Footer, {})] })) : (children) })));
};
export default Layout;
