var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import { AppRouter } from 'app/providers/AppRouter';
import { ErrorBoundary } from 'app/providers/ErrorBoundary';
import { ContextProvider } from 'app/providers/ContextProvider';
import { ApolloProvider } from '@apollo/client';
import { OrganizationProvider } from './providers/OrganizationProvider/organization-provider';
import { useState } from 'react';
import { UserProvider } from './providers/UserProvider/user-provider';
import { createApolloClient } from 'apollo';
import { AuthProvider } from './providers/AuthProvider/auth-provider';
import Layout from 'sections/Layout/Layout';
import { HeaderProvider } from './providers/HeaderProvider/header-provider';
import { BannerProvider } from './providers/BannerProvider/banner-provider';
var App = function () {
    var _a = useState(createApolloClient()), client = _a[0], setClient = _a[1];
    var updateApolloClient = function (accessToken) {
        setClient(createApolloClient(accessToken));
    };
    return (_jsx(ApolloProvider, __assign({ client: client }, { children: _jsx(BrowserRouter, { children: _jsx(ErrorBoundary, { children: _jsx(ContextProvider, { children: _jsx(BannerProvider, { children: _jsx(AuthProvider, __assign({ updateApolloClient: updateApolloClient }, { children: _jsx(UserProvider, { children: _jsx(OrganizationProvider, { children: _jsx(HeaderProvider, { children: _jsx(Layout, { children: _jsx(AppRouter, {}) }) }) }) }) })) }) }) }) }) })));
};
export default App;
