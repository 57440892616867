var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from '../NavigationDropdown.module.css';
import classNames from 'classnames';
import DropdownIconOpen from 'shared/assets/icons/arrow-dropdown-up.svg';
import DropdownIconClose from 'shared/assets/icons/arrow-dropdown-down.svg';
import React from 'react';
export var WebNavigationDropdown = function (props) {
    var _a;
    var children = props.children, title = props.title, isEnterprise = props.isEnterprise, open = props.open, changeDropdownState = props.changeDropdownState, classNamesProps = props.classNamesProps;
    return (_jsxs("div", __assign({ onMouseEnter: function () { return changeDropdownState(true); }, onMouseLeave: function () { return changeDropdownState(false); }, className: classNames(cls.dropdownContainer, (_a = {}, _a[cls.dark] = isEnterprise, _a[cls.open] = open, _a)) }, { children: [_jsxs("button", __assign({ onClick: function () { return changeDropdownState(true); }, className: cls.dropdownButton }, { children: [title, _jsx("div", __assign({ className: cls.icon }, { children: open ?
                            _jsx(DropdownIconOpen, {}) :
                            _jsx(DropdownIconClose, { fill: isEnterprise ? '#fff' : '#161414' }) }))] })), open &&
                _jsx(React.Fragment, { children: _jsx("div", __assign({ className: classNames(cls.dropdown, classNamesProps) }, { children: children })) })] })));
};
