var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BannerContext, BannerTypes } from './banner-context';
import { notification } from 'antd';
export var BannerProvider = function (_a) {
    var children = _a.children;
    var _b = notification.useNotification(), api = _b[0], contextHolder = _b[1];
    var show = function (message, type) {
        if (type === void 0) { type = BannerTypes.ERROR; }
        switch (type) {
            case BannerTypes.ERROR:
                api.error({
                    message: message,
                    placement: 'bottomRight',
                    duration: 0,
                });
                break;
        }
    };
    var value = { show: show };
    return (_jsxs(BannerContext.Provider, __assign({ value: value }, { children: [_jsx("div", { children: children }), contextHolder] })));
};
