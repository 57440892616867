import { UserRoles } from 'app/interfaces/User';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
var useAccessControl = function (role) {
    var user = useUserContext().user;
    var navigate = useNavigate();
    useEffect(function () {
        if (user) {
            switch (role) {
                case UserRoles.ADMIN:
                    if (user.role === UserRoles.USER) {
                        navigate('/dashboard');
                    }
                    break;
                case UserRoles.SUPER_ADMIN:
                    if (user.role !== UserRoles.SUPER_ADMIN) {
                        navigate('/dashboard');
                    }
                    break;
            }
        }
    }, [role, user]);
};
export default useAccessControl;
