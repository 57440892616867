var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import cls from './Footer.module.css';
import FooterLogo from 'shared/assets/logos/footer-logo.svg';
import { RoutePaths } from 'app/providers/AppRouter';
import { Link } from 'react-router-dom';
export var Footer = memo(function () {
    return (_jsx("footer", __assign({ className: cls.footer }, { children: _jsxs("div", __assign({ className: cls.content }, { children: [_jsxs("div", __assign({ className: cls.info }, { children: [_jsx("div", __assign({ className: cls.logoColumn }, { children: _jsx(Link, __assign({ className: cls.logo, to: RoutePaths.DASHBOARD }, { children: _jsx(FooterLogo, {}) })) })), _jsx("p", __assign({ className: cls.copyrights }, { children: "\u00A9 Copyrights 2024. Tomato.ai" }))] })), _jsx("div", __assign({ className: cls.menu }, { children: _jsxs("ul", { children: [_jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/privacy/', title: "Privacy", target: "_blank" }, { children: "Privacy" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/ethics/', title: "Ethics", target: "_blank" }, { children: "Ethics" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/terms/', title: "Terms", target: "_blank" }, { children: "Terms" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: 'https://tomato.ai/request-support/', title: "Request Support", target: "_blank" }, { children: "Request Support" })) })] }) }))] })) })));
});
Footer.displayName = 'Footer';
