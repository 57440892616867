var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Loader from 'shared/components/Loader/Loader';
import cls from './FirebaseActions.module.css';
var FirebaseActionModes;
(function (FirebaseActionModes) {
    FirebaseActionModes["RESET_PASSWORD"] = "resetPassword";
    FirebaseActionModes["VERIFY_EMAIL"] = "verifyEmail";
})(FirebaseActionModes || (FirebaseActionModes = {}));
var FirebaseActions = function () {
    var search = useLocation().search;
    var navigate = useNavigate();
    useEffect(function () {
        var params = new URLSearchParams(search);
        var mode = params.get('mode');
        var oobCode = params.get('oobCode');
        if (!mode || !oobCode) {
            navigate('/login');
        }
        else {
            switch (mode) {
                case FirebaseActionModes.RESET_PASSWORD:
                    navigate('/new-password?oobCode=' + oobCode);
                    break;
                case FirebaseActionModes.VERIFY_EMAIL:
                    navigate('/verify-email?oobCode=' + oobCode);
                    break;
                default:
                    navigate('/login');
                    break;
            }
        }
    }, []);
    return (_jsx("div", __assign({ className: cls.container }, { children: _jsx(Loader, {}) })));
};
export default FirebaseActions;
