var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import cls from './SingleMenuItem.module.css';
import { Text, TextVariants } from 'shared/components';
export var SingleMenuItem = memo(function (props) {
    var active = props.active, item = props.item, index = props.index, onItemChange = props.onItemChange, _a = props.isEnterprise, isEnterprise = _a === void 0 ? false : _a, withIcon = props.withIcon;
    if (isEnterprise) {
        return (_jsxs("li", __assign({ onClick: function () { return onItemChange(index); }, className: cls.menuItem }, { children: [withIcon &&
                    _jsx("div", __assign({ className: cls.icon }, { children: item.iconLight })), _jsx(Text, __assign({ tag: "span", variant: TextVariants.SUBTITLE_MEDIUM_REVERSED, classNamesProps: cls.title }, { children: item.title }))] })));
    }
    return (_jsxs("li", __assign({ onClick: function () { return onItemChange(index); }, className: cls.menuItem }, { children: [withIcon &&
                _jsxs("div", __assign({ className: cls.icon }, { children: [active && item.iconDark, !active && item.iconLight] })), _jsx(Text, __assign({ tag: "p", variant: active ? TextVariants.SUBTITLE_MEDIUM : TextVariants.SUBTITLE_MEDIUM_REVERSED, classNamesProps: cls.title }, { children: item.title }))] })));
});
SingleMenuItem.displayName = 'SingleMenuItem';
