var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Card from 'shared/components/Card/Card';
import Table from 'shared/components/TableOld/Table';
import cls from './UsersScreen.module.css';
import DeleteIcon from 'shared/assets/icons/delete-icon.svg';
import DeleteIconDisabled from 'shared/assets/icons/delete-icon-disabled.svg';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { Button, ButtonVariants } from 'shared/components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useBannerContext } from 'app/providers/BannerProvider/banner-context';
import { UserRoles } from 'app/interfaces/User';
import ConfirmationModal from 'widgets/ConfirmationModal/ConfirmationModal';
import EditableText from 'shared/components/EditableText/EditableText';
import EditableSelect from 'shared/components/EditableSelect/EditableSelect';
import InviteUserModal from 'widgets/InviteUserModal/InviteUserModal';
var tableHeaders = [
    'First Name',
    'Last Name',
    'Email',
    'Role',
    'Invoices',
    'Status',
    'Actions',
];
var USERS_BY_ORGANIZATION_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query UsersByOrganization($organizationId: String!) {\n        usersByOrganization(organizationId: $organizationId) {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            role\n            invoices\n            active\n        }\n    }\n"], ["\n    query UsersByOrganization($organizationId: String!) {\n        usersByOrganization(organizationId: $organizationId) {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            role\n            invoices\n            active\n        }\n    }\n"])));
var DELETE_USER_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation DeleteUser($id: ID!) {\n        deleteUser(id: $id)\n    }\n"], ["\n    mutation DeleteUser($id: ID!) {\n        deleteUser(id: $id)\n    }\n"])));
var UPDATE_USER_MUTATION = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation UpdateUser($userId: String!, $input: UpdateUserInput!) {\n        updateUser(userId: $userId, input: $input) {\n            id\n        }\n    }\n"], ["\n    mutation UpdateUser($userId: String!, $input: UpdateUserInput!) {\n        updateUser(userId: $userId, input: $input) {\n            id\n        }\n    }\n"])));
var UsersScreen = function () {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var user = useUserContext().user;
    var show = useBannerContext().show;
    var _c = useState(''), userToDelete = _c[0], setUserToDelete = _c[1];
    var _d = useState(false), isEditing = _d[0], setIsEditing = _d[1];
    var _e = useState({}), editValues = _e[0], setEditValues = _e[1];
    var _f = useState(false), showInviteModal = _f[0], setShowInviteModal = _f[1];
    var usersQuery = useQuery(USERS_BY_ORGANIZATION_QUERY, {
        variables: {
            organizationId: user === null || user === void 0 ? void 0 : user.organization.id,
        },
        skip: !user,
    });
    var deleteUserMutation = useMutation(DELETE_USER_MUTATION)[0];
    var updateUserMutation = useMutation(UPDATE_USER_MUTATION)[0];
    var onDeleteClick = function (id) {
        setUserToDelete(id);
    };
    var onDeleteConfirm = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, deleteUserMutation({
                            variables: {
                                id: id,
                            },
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.errors) return [3 /*break*/, 2];
                    show(result.errors[0].message);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, usersQuery.refetch()];
                case 3:
                    _a.sent();
                    setUserToDelete('');
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    show(e_1.message);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var onDeleteCancel = function () {
        setUserToDelete('');
    };
    var toggleEdit = function () {
        setIsEditing(!isEditing);
    };
    var onChange = function (id, field, value) {
        var _a, _b;
        setEditValues(__assign(__assign({}, editValues), (_a = {}, _a[id] = __assign(__assign({}, editValues[id]), (_b = {}, _b[field] = field === 'invoices' ? value === 'yes' : value, _b)), _a)));
    };
    var onSaveClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    return [4 /*yield*/, Promise.all(Object.keys(editValues).map(function (id) {
                            return updateUserMutation({
                                variables: {
                                    userId: id,
                                    input: __assign({}, editValues[id]),
                                },
                            });
                        }))];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, usersQuery.refetch()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    e_2 = _a.sent();
                    show(e_2.message);
                    return [3 /*break*/, 5];
                case 4:
                    setIsEditing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a, _b;
        if ((_a = usersQuery.data) === null || _a === void 0 ? void 0 : _a.usersByOrganization) {
            var values_1 = {};
            (_b = usersQuery.data) === null || _b === void 0 ? void 0 : _b.usersByOrganization.forEach(function (_a) {
                var firstName = _a.firstName, lastName = _a.lastName, invoices = _a.invoices, id = _a.id, role = _a.role;
                values_1[id] = {
                    firstName: firstName,
                    lastName: lastName,
                    invoices: invoices,
                    role: role,
                };
            });
            setEditValues(values_1);
        }
    }, [usersQuery, isEditing]);
    useEffect(function () {
        if (!usersQuery.loading) {
            if (usersQuery.error) {
                show(usersQuery.error.message);
            }
            else if (usersQuery.data) {
                setData(usersQuery.data.usersByOrganization.map(function (_a) {
                    var _b, _c, _d, _e;
                    var userId = _a.id, firstName = _a.firstName, lastName = _a.lastName, email = _a.email, active = _a.active, role = _a.role;
                    var rolesSelectOptions = [];
                    if ((user === null || user === void 0 ? void 0 : user.role) === UserRoles.SUPER_ADMIN) {
                        rolesSelectOptions.push({
                            value: UserRoles.SUPER_ADMIN,
                            label: 'Super Admin',
                        });
                        if ((user === null || user === void 0 ? void 0 : user.id) !== userId) {
                            rolesSelectOptions.push({
                                value: UserRoles.ADMIN,
                                label: 'Admin',
                            });
                            rolesSelectOptions.push({
                                value: UserRoles.USER,
                                label: 'User',
                            });
                        }
                    }
                    else {
                        if (role === UserRoles.SUPER_ADMIN) {
                            rolesSelectOptions.push({
                                value: UserRoles.SUPER_ADMIN,
                                label: 'Super Admin',
                            });
                        }
                        else if ((user === null || user === void 0 ? void 0 : user.id) === userId) {
                            rolesSelectOptions.push({
                                value: UserRoles.ADMIN,
                                label: 'Admin',
                            });
                        }
                        else {
                            rolesSelectOptions.push({
                                value: UserRoles.ADMIN,
                                label: 'Admin',
                            });
                            rolesSelectOptions.push({
                                value: UserRoles.USER,
                                label: 'User',
                            });
                        }
                    }
                    var hideDelete = userId === (user === null || user === void 0 ? void 0 : user.id) ||
                        ((user === null || user === void 0 ? void 0 : user.role) === UserRoles.ADMIN &&
                            role === UserRoles.SUPER_ADMIN);
                    return [
                        _jsx(EditableText, { id: "firstName", inputClassName: cls.textInput, edit: isEditing, value: (_b = editValues[userId]) === null || _b === void 0 ? void 0 : _b.firstName, onChange: function (field, value) {
                                return onChange(userId, field, value);
                            } }, "0"),
                        _jsx(EditableText, { id: "lastName", inputClassName: cls.textInput, edit: isEditing, value: (_c = editValues[userId]) === null || _c === void 0 ? void 0 : _c.lastName, onChange: function (id, value) {
                                return onChange(userId, id, value);
                            } }, "1"),
                        _jsx("span", __assign({ className: cls.email }, { children: email }), "2"),
                        _jsx(EditableSelect, { id: "role", value: (_d = editValues[userId]) === null || _d === void 0 ? void 0 : _d.role, edit: isEditing, onChange: function (id, value) {
                                return onChange(userId, id, value);
                            }, options: rolesSelectOptions, inputClassName: "".concat(cls.selectInput, " user_select_input") }, "3"),
                        _jsx(EditableSelect, { id: "invoices", value: ((_e = editValues[userId]) === null || _e === void 0 ? void 0 : _e.invoices)
                                ? 'yes'
                                : 'no', edit: isEditing, onChange: function (key, value) {
                                return onChange(userId, key, value);
                            }, options: [
                                { value: 'yes', label: 'Yes' },
                                { value: 'no', label: 'No' },
                            ], inputClassName: "".concat(cls.selectInput, " user_select_input") }, "4"),
                        _jsx("div", __assign({ className: active ? cls.active : cls.inactive }, { children: active ? 'Active' : 'Inactive' }), 5),
                        hideDelete ? (_jsx("div", __assign({ className: cls.actions }, { children: _jsx(DeleteIconDisabled, {}) }), 2)) : (_jsx(ConfirmationModal, __assign({ open: userId === userToDelete, text: _jsxs("span", __assign({ className: cls.deleteConfirmationText }, { children: ["Are you sure you want to delete user", ' ', _jsxs("span", { children: [firstName, " ", lastName] }), "?"] })), onConfirm: function () {
                                return onDeleteConfirm(userId);
                            }, onCancel: onDeleteCancel }, { children: _jsx("div", __assign({ className: cls.actions }, { children: _jsx(DeleteIcon, { onClick: function () {
                                        return onDeleteClick(userId);
                                    } }) }), 2) }))),
                    ];
                }));
                setLoading(false);
            }
        }
    }, [usersQuery, userToDelete, isEditing, editValues]);
    if (!user)
        return null;
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: cls.pageHeader }, { children: [_jsx("h1", { children: "Manage Users" }), _jsx(Button, { text: "Invite User", variant: ButtonVariants.ACTION_BLUE, type: "button", classNamesProps: cls.inviteButton, onClick: function () { return setShowInviteModal(true); } })] })), _jsxs(Card, __assign({ className: cls.usersCard, id: "users-card" }, { children: [_jsxs("div", __assign({ className: cls.cardHeader }, { children: [_jsxs("div", { children: ["Company: ", _jsx("span", { children: user.organization.name })] }), isEditing ? (_jsxs("div", __assign({ className: cls.editButtons }, { children: [_jsx(Button, { text: "Cancel", variant: ButtonVariants.ACTION, type: "button", classNamesProps: cls.cancelButton, onClick: toggleEdit }), _jsx(Button, { text: "Save", variant: ButtonVariants.ACTION, type: "button", classNamesProps: cls.editButton, onClick: onSaveClick })] }))) : (_jsx(Button, { text: "Edit", variant: ButtonVariants.ACTION, type: "button", classNamesProps: cls.editButton, onClick: toggleEdit }))] })), _jsx(Table, { headers: tableHeaders, data: data, loading: loading, noDataLabel: "No Users", pagination: true })] })), _jsx(InviteUserModal, { organizationName: user.organization.name, organizationId: user.organization.id, inviterId: user.id, open: showInviteModal, onClose: function () { return setShowInviteModal(false); }, refetchUsers: usersQuery.refetch })] }));
};
export default UsersScreen;
var templateObject_1, templateObject_2, templateObject_3;
