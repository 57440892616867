var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import cls from './NavigationLink.module.css';
import ArrowGreenIcon from 'shared/assets/icons/arrow-green-right.svg';
export var NavigationLinkVariants;
(function (NavigationLinkVariants) {
    NavigationLinkVariants["DEFAULT"] = "default";
    NavigationLinkVariants["ACTION"] = "action";
    NavigationLinkVariants["OUTLINED"] = "outlined";
    NavigationLinkVariants["ACTION_BLUE"] = "actionBlue";
    NavigationLinkVariants["OUTLINED_BLUE"] = "outlinedBlue";
    NavigationLinkVariants["ACTION_TEXT"] = "actionText";
    NavigationLinkVariants["FOOTER"] = "footer";
})(NavigationLinkVariants || (NavigationLinkVariants = {}));
export var NavigationLink = memo(function (props) {
    var _a;
    var text = props.text, to = props.to, variant = props.variant, classNamesProps = props.classNamesProps, _b = props.isEnterprise, isEnterprise = _b === void 0 ? false : _b, onClick = props.onClick;
    var classes = classNames(cls.navigationLink, cls[variant], classNamesProps, (_a = {}, _a[cls.dark] = isEnterprise, _a));
    return (_jsxs(Link, __assign({ to: to, className: classes, onClick: onClick }, { children: [text, _jsx("div", __assign({ className: cls.icon }, { children: variant === NavigationLinkVariants.ACTION_TEXT && _jsx(ArrowGreenIcon, {}) }))] })));
});
NavigationLink.displayName = 'NavigationLink';
