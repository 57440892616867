var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'antd';
import { useMemo } from 'react';
import cls from './SelectInput.module.css';
import clx from 'classnames';
var SelectInput = function (_a) {
    var _b;
    var label = _a.label, options = _a.options, value = _a.value, onChange = _a.onChange, className = _a.className, inputClassName = _a.inputClassName, labelClassName = _a.labelClassName, id = _a.id, disabled = _a.disabled, error = _a.error, size = _a.size;
    var parsedOptions = useMemo(function () {
        return options.map(function (_a) {
            var value = _a.value, label = _a.label;
            return ({
                value: value,
                label: _jsx("span", { children: label }),
            });
        });
    }, [options]);
    return (_jsxs("div", __assign({ className: clx([
            'select_container',
            cls.container,
            className,
            disabled ? cls.disable_select : '',
        ]), id: id }, { children: [label ? (_jsx("label", __assign({ className: clx(['select_label', cls.label, labelClassName]) }, { children: label }))) : null, _jsx(Select, { className: clx([
                    cls.select,
                    inputClassName,
                    size === 'sm' ? "".concat(cls.sm_select, " sm_select") : '',
                    size === 'xs' ? "".concat(cls.xs_select, " xs_select") : '',
                ], (_b = {},
                    _b[cls.error] = error,
                    _b)), getPopupContainer: function (trigger) {
                    return trigger.parentElement || document.body;
                }, options: parsedOptions, popupClassName: "".concat(cls.popup, " select_popup"), suffixIcon: _jsx("div", { className: "".concat(cls.suffix, " suffix_icon") }), value: value, onChange: onChange, disabled: disabled }), error ? _jsx("div", __assign({ className: cls.errorMessage }, { children: error })) : null] })));
};
export default SelectInput;
