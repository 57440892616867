// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
export var FirebaseErrors;
(function (FirebaseErrors) {
    FirebaseErrors["INVALID_LOGIN_CREDENTIALS"] = "auth/invalid-login-credentials";
    FirebaseErrors["INVALID_VERIFICATION_CODE"] = "auth/invalid-verification-code";
    FirebaseErrors["EMAIL_ALREADY_IN_USE"] = "auth/email-already-in-use";
    FirebaseErrors["MULTI_FACTOR_REQUIRED"] = "auth/multi-factor-auth-required";
})(FirebaseErrors || (FirebaseErrors = {}));
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
var app = initializeApp(firebaseConfig);
var auth = getAuth(app);
export { auth };
