var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import cls from './Users.module.css';
import { Helmet } from 'react-helmet';
import UsersScreen from 'sections/UsersScreen/UsersScreen';
import { useHeaderContext } from 'app/providers/HeaderProvider/header-context';
import { UserRoles } from 'app/interfaces/User';
import useAccessControl from 'app/hooks/useAccessControl';
var Users = function () {
    var _a = useHeaderContext(), setActions = _a.setActions, setPageTitle = _a.setPageTitle;
    useAccessControl(UserRoles.ADMIN);
    useEffect(function () {
        setPageTitle('');
        setActions(null);
    }, []);
    return (_jsxs("main", __assign({ className: cls.main }, { children: [_jsx(Helmet, { children: _jsx("title", { children: "Tomato.ai Web Console | Users" }) }), _jsx(UsersScreen, {})] })));
};
export default Users;
