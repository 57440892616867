var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import cls from './Header.module.css';
import { Text, TextVariants } from 'shared/components';
import { useHeaderContext } from 'app/providers/HeaderProvider/header-context';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import Avatar from 'antd/es/avatar/avatar';
import { useNavigate } from 'react-router';
import { UserRoles } from 'app/interfaces/User';
import { Dropdown, Space, Typography } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useBannerContext } from 'app/providers/BannerProvider/banner-context';
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
// import DowntimeBanner from './components/DowntimeBanner/DowntimeBanner';
import { DownOutlined } from '@ant-design/icons';
var USERS_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query GetUsers {\n        activeUsers {\n            id\n            firebaseUid\n            firstName\n            lastName\n            organization {\n                name\n            }\n        }\n    }\n"], ["\n    query GetUsers {\n        activeUsers {\n            id\n            firebaseUid\n            firstName\n            lastName\n            organization {\n                name\n            }\n        }\n    }\n"])));
var PROXY_LOGIN_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation ProxyLogin($firebaseUid: String!) {\n        proxyLogin(firebaseUid: $firebaseUid)\n    }\n"], ["\n    mutation ProxyLogin($firebaseUid: String!) {\n        proxyLogin(firebaseUid: $firebaseUid)\n    }\n"])));
export var Header = memo(function () {
    var _a = useHeaderContext(), actions = _a.actions, pageTitle = _a.pageTitle;
    var user = useUserContext().user;
    var navigate = useNavigate();
    var show = useBannerContext().show;
    var proxyLogin = useAuthContext().proxyLogin;
    var _b = useState([{ label: 'Loading...', key: '1', disabled: true }]), proxyLoginOptions = _b[0], setProxyLoginOptions = _b[1];
    var usersQuery = useQuery(USERS_QUERY, {
        skip: (user === null || user === void 0 ? void 0 : user.role) !== UserRoles.SUPER_ADMIN,
    });
    var proxyLoginMutation = useMutation(PROXY_LOGIN_MUTATION)[0];
    useEffect(function () {
        if (!usersQuery.loading) {
            if (usersQuery.error) {
                show('Error fetching users');
            }
            else if (usersQuery.data) {
                setProxyLoginOptions(usersQuery.data.activeUsers.map(function (user) {
                    var _a;
                    return ({
                        label: "".concat(user.firstName, " ").concat(user.lastName, " - ").concat((_a = user.organization) === null || _a === void 0 ? void 0 : _a.name),
                        key: user.firebaseUid,
                    });
                }));
            }
        }
    }, [usersQuery]);
    var onProxyLoginClick = function (_a) {
        var key = _a.key;
        return __awaiter(void 0, void 0, void 0, function () {
            var _b, data, errors, error;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, proxyLoginMutation({
                            variables: { firebaseUid: key },
                        })];
                    case 1:
                        _b = _c.sent(), data = _b.data, errors = _b.errors;
                        if (!(data === null || data === void 0 ? void 0 : data.proxyLogin)) return [3 /*break*/, 3];
                        return [4 /*yield*/, proxyLogin(data === null || data === void 0 ? void 0 : data.proxyLogin)];
                    case 2:
                        error = _c.sent();
                        if (error) {
                            show(error);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        show(JSON.stringify(errors));
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return (_jsx("header", __assign({ className: cls.header }, { children: _jsxs("div", __assign({ className: cls.content }, { children: [_jsxs("div", __assign({ className: cls.actions }, { children: [pageTitle ? (_jsx(Text, __assign({ tag: "h1", variant: TextVariants.TITLE_EXTRA_SMALL }, { children: pageTitle }))) : null, actions] })), _jsxs("div", __assign({ className: cls.profileMenu }, { children: [_jsxs("div", __assign({ className: cls.avatar_info }, { children: [_jsx(Text, __assign({ tag: "p", classNamesProps: cls.usertitle, variant: TextVariants.PARAGRAPH_TITLE }, { children: user && "".concat(user.firstName, " ").concat(user.lastName) })), (user === null || user === void 0 ? void 0 : user.role) === UserRoles.SUPER_ADMIN ? (_jsx(_Fragment, { children: _jsx(Dropdown, __assign({ menu: {
                                            items: proxyLoginOptions,
                                            onClick: onProxyLoginClick,
                                            selectable: true,
                                            defaultSelectedKeys: ['3'],
                                        }, className: cls.proxy_login, rootClassName: cls.proxy_login_dropdown }, { children: _jsx(Typography.Link, { children: _jsxs(Space, __assign({ size: 5 }, { children: ["All Users", _jsx(DownOutlined, { size: 5 })] })) }) })) })) : (_jsx(Typography.Link, { children: _jsx(Space, __assign({ size: 5 }, { children: user === null || user === void 0 ? void 0 : user.role })) }))] })), _jsx(Avatar, __assign({ className: cls.avatar, alt: "user avatar", size: 46, onClick: function () { return navigate('/profile'); } }, { children: user && user.firstName.charAt(0).toUpperCase() }))] }))] })) })));
});
Header.displayName = 'Header';
var templateObject_1, templateObject_2;
